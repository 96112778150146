<template>
  <b-alert
    v-if="registrationWarnings && registrationWarnings.errors && registrationWarnings.errors.length > 0"
    show
    variant="warning"
  >
    <div class="d-flex align-items-center">
      <div class="pl-2">
        <feather-icon
          icon="AlertTriangleIcon"
          class="mr-50"
          size="28"
        />
      </div>

      <div class="alert-body font-small-3">
        <span class="font-medium-2 pb-1 d-block">Alertas da validação de cadastro</span>
        <span
          v-for="error in registrationWarnings.errors"
          :key="`${error.field}-${error.validation}`"
          class="d-block"
        >
          - {{ getTitle(error.field) }}: {{ error.error }}
        </span>
      </div>
    </div>
  </b-alert>
</template>

<script>
import {
  BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },

  props: {
    registrationWarnings: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      fields: {
        products_want_to_represent: 'Produtos que deseja representar',
        represented_products: 'Produtos que já representou',
        main_customers: 'Principais clientes',
        where_did_working: 'Marcas',
        email: 'E-mail',
        emails: 'E-mail Adicional',
        link_site: 'Site',
        link_instagram: 'Instagram',
        link_linkedin: 'Linkedin',
        link_facebook: 'Facebook',
        presentation: 'Apresentação',
      },
      userService: null,
    }
  },

  created() {
  },

  methods: {
    getTitle(field) {
      if (this.fields[field]) {
        return this.fields[field]
      }

      return field
    },
  },
}
</script>
<style lang="scss">
</style>
